import React from 'react'

const Agreements = props => (
  <div>
    <h1>Согласие на обработку персональных данных</h1>
    <p>
      Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных , зарегистрированным в соответствии с законодательством РФ по адресу:
      (далее по тексту - Оператор).
    </p>
    <p>
      Персональные данные - любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.
      <br/>
      Настоящее Согласие выдано мною на обработку следующих персональных данных:
    </p>
    <ul>
      <li>Организация;</li>
      <li>Контактное лицо;</li>
      <li>Телефон;</li>
      <li>E-mail;</li>
      <li>Адрес доставки;</li>
    </ul>
    <p>
      Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.
    </p>

    <ul>
      Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:
      <li>предоставление мне услуг/работ;</li>
      <li>направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;</li>
      <li>подготовка и направление ответов на мои запросы;</li>
      <li>направление в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора.</li>
    </ul>
    <p>
      Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес klishe@platinum-group.ru. В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.06.2006 г.
    </p>
  </div>
)

export default Agreements;
