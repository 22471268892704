import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Hero from '../components/hero'
import ContactMap from '../components/map';
import OrderForm from '../components/form';

import benefits from "../styles/benefit.module.css"
import features from "../styles/feature.module.css"
import photos from "../styles/photos.module.css"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      allInstaNode {
        edges {
          node {
            localFile {
              childImageSharp {
                fixed(width: 350, height: 350) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            caption
            id
            thumbnails {
              src
            }
          }
        }
      }

      headerImage: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 773,
            maxHeight: 769,
            quality: 70,
            traceSVG: { background: "#edeef0", color: "#9fa5ad" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
        }
      }
      guaranteeImage: file(relativePath: { eq: "guarantee.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 515,
            maxHeight: 465,
            quality: 70,
            traceSVG: { background: "#edeef0", color: "#9fa5ad" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
        }
      }
    }
  `)

  return (
    <Layout><div className="index">
      <Hero BgImage={data.headerImage}></Hero>
      <section className={benefits.wrap}>
        <div className={benefits.img}>
          <Img fluid={data.guaranteeImage.childImageSharp.fluid} />
        </div>
        <h2 className={benefits.title}>Наши преимущества</h2>
        <ul className={benefits.list}>
          <li>Работаем с 2011 года</li>
          <li>Срочные заказы, в течении 1-3 часа</li>
          <li>Точность оборудования 0,001мм</li>
          <li>Магниевые листы (Magnesium Electron)</li>
          <li>Инструменты HANITA, MICRO 100, Best Carbide, Hitachi Tool, Kyocera</li>
          <li>Контроль качества на всех этапах производства</li>
          <li>Работаем без выходных</li>
          <li>Бесплатная курьерская доставка по городу Казань</li>
        </ul>
      </section>
      <section className={features.wrap}>
        <ul className={features.list}>
          <li>
            Максимальный формат
            <strong>410*510мм</strong>
          </li>
          <li>
            На выбор толщина листов<strong>2мм, 4мм, 6.35мм, 7 мм</strong>
          </li>
          <li className={features.divider}></li>
          <li>Глубина гравировки
            <strong>от 0.10мм до 5мм</strong>
          </li>
          <li>
            Патрицы (ответные части к конгревным клише)
            <strong>из магния толщиной 2мм или 4мм</strong>
          </li>
        </ul>
      </section>
      <section className={photos.wrap}>
        <h2 className={photos.title}>
          Наши работы
        </h2>
        <ul className={photos.grid}>
          {data.allInstaNode.edges.map(({ node }, index) => (
            <li key={index}>
              <a href={'https://instagram.com/p/' + node.id} rel="noopener noreferrer" target="_blank" title={node.caption}>
                <img src={node.thumbnails[4].src} alt={node.caption} />
              </a>
            </li>
           ))}
        </ul>
        <a className={photos.all} href="https://www.instagram.com/klishe_rf/" rel="noopener noreferrer" target="_blank">посмотреть ещё работы</a>
      </section>
      <OrderForm></OrderForm>
      <ContactMap/>
    </div>
  </Layout>)
}

export default IndexPage
