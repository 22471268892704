import React from 'react'
import Img from "gatsby-image"

import styles from "../styles/hero.module.css"

const Hero = props => (
  <div className={styles.wrap}>
    <div className={styles.msg}>
      <h1 className={styles.title}>Изготовим клише&nbsp;из&nbsp;магния за&nbsp;один&nbsp;день</h1>
      <p className={styles.subtitle}>Доставка в любые регионы&nbsp;России&nbsp;и&nbsp;СНГ</p>
      <a href="#OrderForm" className={styles.button}>Заказать</a>
      <a className={styles.question} target="_blank" href="https://wa.me/79033052222" rel="noopener noreferrer">
        Задать вопрос в WhatsApp
        <small>+7 903 305 22 22</small>
      </a>
    </div>
    <div className={styles.image}>
      <Img fluid={props.BgImage.childImageSharp.fluid} />
    </div>
  </div>
)

export default Hero;
