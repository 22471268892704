import React from 'react'

const Requirements = props => (
  <div>
    <h1>Рекомендации по подготовке макетов для&nbsp;клише</h1>
    <ol>
      <li>Макет должен быть без обводок, без наложения объектов, без скрытых объектов, все объекты черные и замкнутые , все пересекающиеся вектора (объекты, линии) должны быть объединены;</li>
      <li>Расстояние между объектами не должно быть менее 0,15 - 0,20мм;</li>
      <li>Необходимо оставлять технологические поля по 1см с каждой стороны от изображения, которые в дальнейшем добавляются в расчетную площадь;</li>
      <li>Предоставляемое изображение рекомендуем уменьшать с учетом коэффициента термического расширения металла: для магния 0,0029% на 1°С;</li>
      <li>Макет принимаются в формате eps, ai, cdr, pdf CorelDraw до 16-версии;</li>
      <li>шрифты в кривых, фон и изображения в CMYK, 100% black и 100% white.</li>
    </ol>
    <p>При не соблюдении указанных требований, всю ответственность за достоверность изображения на готовом клише, берет на себя заказчик.</p>
  </div>
)

export default Requirements;
