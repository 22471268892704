import { YMaps, Map, Placemark } from 'react-yandex-maps';
import React from 'react'

import styles from "../styles/map.module.css"

const mapState = {
  center: [55.755792, 49.244917],
  zoom: 17,
};

const placeMark = {
  geometry: [55.755792, 49.244917],
  properties: {
      iconCaption: 'Салиха Батыева 21, офис 6',
      balloonContent: '420129, Казань, улица Салиха Батыева 21, офис 6<br>+7 (843) 253-22-22<br>+7 (843) 253-36-36'
  }
  
}

const ContactMap = () => (
  <YMaps>
    <Map defaultState={mapState} className={styles.map} instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}>
      <Placemark {...placeMark} modules={['geoObject.addon.balloon', 'geoObject.addon.hint']} />
    </Map>
  </YMaps>
);

export default ContactMap;
