import React from 'react';
import form from "../styles/form.module.css";
import Requirements from '../components/requirements';
import Agreements from '../components/agreement';
import axios from 'axios';

// const API_PATH = 'http://rm-centr.ru/request/';
// const API_PATH = 'http://platinum-group.ru/request/index.php';
// const API_PATH = 'http://www.xn----8sbarmcndf1a8i7b.xn--p1ai/request/';
const API_PATH = '/request/';

export default class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.phoneInput = React.createRef();
    this.contactInput = React.createRef();

    this.state = {
      bot: "",
      client: "",
      contact: "",
      phone: "",
      email: "",
      order: "",
      type: "Конгрев",
      fabric: "Бумага",
      amount: "1",
      comments: "",
      attachment: "",
      mailSent: false,
      error: null,
      agree: true,
      modal: ''
    }
  }

  onChange = e => {
    this.setState({attachment:e.target.files[0]})
  }

  resetForm = e => {
    this.setState( {
      mailSent: false
    })
  }

  showModal = (e, type) => {
    e.preventDefault();
    this.setState( {
      modal: type
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState( {
      errorField: null,
      errorMsg: null
    });

    this.setState({
      [name]: value,
    })
  }

  handleFile = e => {
    const target = e.target
    const value = target.files[0]
    const name = target.name

    this.setState( {
      errorField: null,
      errorMsg: null
    });

    this.setState({
      [name]: value,
    })
  };

  handleFormSubmit = e => {
    e.preventDefault();

    if(this.state.contact === '') {
      this.setState( {
        errorField: 'contact',
        errorMsg: 'Как к вам обращаться?'
      })
      this.contactInput.current.focus();

    } else if (this.state.phone === '') {
      this.setState( {
        errorField: 'phone',
        errorMsg: 'Позвоним, если только будут вопросы'
      })
      this.phoneInput.current.focus();

    } else if (this.state.email === '') {
      this.setState( {
        errorField: 'email',
        errorMsg: 'На email мы отправим счёт и документы'
      })
      this.emailInput.current.focus();

    } else if (this.state.attachment === '') {
      this.setState( {
        errorField: 'file',
        errorMsg: 'Без макета расчёт будет не точным'
      })
      // this.fileInput.current.focus();
    } else {
      const formData = new FormData();
      formData.append('attachment', this.state.attachment);
      formData.append('client', this.state.client);
      formData.append('bot', this.state.bot);
      formData.append('contact', this.state.contact);
      formData.append('phone', this.state.phone);
      formData.append('email', this.state.email);
      formData.append('type', this.state.type);
      formData.append('fabric', this.state.fabric);
      formData.append('amount', this.state.amount);
      formData.append('comments', this.state.comments);

      // data: this.state,

      axios({
          method: 'post',
          url: `${API_PATH}`,
          data: formData,
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(result => {
        this.setState( {
          mailSent: result.data.sent
        })
      })
      .catch(error => this.setState( { error: error.message } ));
    }
  };

  render() {
    return (
      <form id="OrderForm" className={form.wrap} action={API_PATH} method="post">
        {this.state.modal && <div className={form.modal}><div className={form.modalWrap}>
            <button className={form.close} onClick={e => this.setState({ modal: '' })}>&times;</button>
            {this.state.modal === 'requirements' && <Requirements/> }
            {this.state.modal === 'agreements' && <Agreements /> }
          </div></div>
        }
        {this.state.mailSent  &&
          <div className={form.success}>
            <h1>Спасибо, заявка получена</h1>
            <p>Мы отправим расчёт в ближайшее время.</p>
            <button type="button"
                    onClick={e => this.resetForm(e)}
                    className={form.resetForm}
                    >отправить еще одну</button>
          </div>
        }
        {!this.state.mailSent  &&
          <div className="form-fields">
            <h1 className={form.title}>Расчёт стоимости</h1>
            <fieldset>
              <input type="number" name="bot" id="bot" onChange={this.handleChange} className={form.bot} />
              <div className={form.group}>
                <label className={form.label} htmlFor="client">
                  Организация
                </label>
                <input
                  type="text"
                  placeholder="Наименование (ООО, ИП)"
                  name="client"
                  id="client"
                  value={this.state.client}
                  className={form.input}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="contact">
                  Контактное лицо
                </label>
                <input
                  type="text"
                  name="contact"
                  id="contact"
                  placeholder="Имя Фамилия"
                  value={this.state.contact}
                  className={form.input}
                  onChange={this.handleInputChange}
                  ref={this.contactInput}
                  required=""
                />
                {this.state.errorField === "contact" &&
                  <div className={form.error}>{this.state.errorMsg}</div>
                }
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="phone">
                  Телефон
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={this.state.phone}
                  className={form.inputSm}
                  onChange={this.handleInputChange}
                  ref={this.phoneInput}
                  required=""
                />
                {this.state.errorField === "phone" &&
                  <div className={form.error}>{this.state.errorMsg}</div>
                }
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  className={form.input}
                  ref={this.emailInput}
                  onChange={this.handleInputChange}
                  required=""
                />
                {this.state.errorField === "email" &&
                  <div className={form.error}>{this.state.errorMsg}</div>
                }
              </div>
            </fieldset>
            <fieldset>
              <div className={form.group}>
                <label className={form.label} htmlFor="type">
                  Вид клише
                </label>
                <select id="type" name="type" onBlur={this.handleChange}>
                  <option value="Конгрев">Конгрев</option>
                  <option value="Тиснение">Тиснение</option>
                  <option value="Блинт">Блинт</option>
                </select>
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="fabric">
                  Материал тиснения
                </label>
                <select id="fabric" name="fabric" onBlur={this.handleChange}>
                  <option value="Бумага">Бумага</option>
                  <option value="Кожа">Кожа</option>
                  <option value="Дерево">Дерево</option>
                  <option value="Другое">Другое</option>
                </select>
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="amount">
                  Количество клише
                </label>
                <input
                  type="number"
                  name="amount"
                  min="1"
                  id="amount"
                  value={this.state.amount || 1}
                  className={form.inputSm}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={form.group}>
                <label className={form.label} htmlFor="comments">
                  Комментарии
                </label>
                <textarea
                  name="comments"
                  id="comments"
                  value={this.state.comments}
                  className={form.input}
                  onChange={this.handleInputChange}
                ></textarea>
              </div>

              <div className={form.group}>
                <label className={form.label} htmlFor="attachment">
                  Макет <small>(.pdf, .cdr, .eps, .ai)</small>
                  <br/>
                </label>
                <input
                  type="file"
                  name="attachment"
                  id="attachment"
                  accept=".pdf, .cdr, .eps, .ai"
                  onChange={e => this.handleFile(e)}
                />
                {this.state.errorField === "file" &&
                  <div className={form.error}>{this.state.errorMsg}</div>
                }
              </div>
              <div className={form.groupNote}>
                <a role="button" href="/requirements" target="_blank" onClick={e => this.showModal(e, 'requirements')}>
                  Рекомендации по подготовке макетов
                </a>
              </div>
            </fieldset>
            <div className={form.group}>
              <label className={form.agreement}>
                <input type="checkbox"
                       defaultChecked="true"
                       onChange={e => this.setState({ agree: e.target.checked })} />

                Нажимая кнопку «Запросить расчёт», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в 
                <a role="button" href="/agreement" target="_blank" onClick={e => this.showModal(e, 'agreements')}> Согласии на обработку персональных данных</a>.
              </label>
            </div>
            <button type="submit"
                    onClick={e => this.handleFormSubmit(e)}
                    className={form.btn}
                    disabled={!this.state.agree}
                    >Запросить расчёт</button>
        </div>}
      </form>
    )
  }
}
