import React from 'react'
import styles from "../styles/header.module.css"

const Header = ({ siteTitle }) => (
  <div className={styles.head}>
    <div className={styles.contacts}>
      <a href="tel:+78432532222" className={styles.tel}>8 (843) 253 22 22</a>
      <br/>
      <a className={styles.email} href="mailto:klishe@platinum-group.ru">klishe@platinum-group.ru</a>
    </div>
  </div>
)

export default Header
